String.prototype.escapeHTML = function(){
	var text = document.createTextNode(this);
	var div = document.createElement('div');
	div.appendChild(text);
	return div.innerHTML;
}

Number.prototype.nth = function ordinal_suffix_of(){

	let j = this % 10,
	k = this % 100;
	if (j == 1 && k != 11) {
		return this + "st";
	}
	if (j == 2 && k != 12) {
		return this + "nd";
	}
	if (j == 3 && k != 13) {
		return this + "rd";
	}
	return this + "th";
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

module.exports.mergeDeep = function (target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}