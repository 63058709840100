import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import WeekSelector from '../components/week_selector';
import { gameIsLive, getGameStatusString, getGameScoreString, getVsString } from '../helpers';
import 'ws';
import players from './players';

let ws
const connectToServer = async () => {
	const protocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';
	const port = process.env.NODE_ENV === 'production'  ? ':3001' : '';
	const websocketurl = `${protocol}://${location.host}${port}`;
	const ws = new WebSocket(websocketurl);
	return new Promise((resolve, reject) => {
		const timer = setInterval(() => {
			if (ws.readyState === 1) {
				console.log('websocket ready')
				clearInterval(timer)
				resolve(ws);
			}
		}, 10);
	});
}

const queryString = require('query-string');
const POSITIONS = require('../constants').POSITIONS;
const TEAMS = require('../constants').TEAMS;
const getWeek = require('../helpers').getWeek;

const container = document.getElementById('matchup');
const weekSelector = new WeekSelector(container.querySelector('select[name="week"]'));
weekSelector.setOnChangeListener((value) => {
	app.modules.show('matchup', null, null, value);
});

//  const stat = new StatModel({name: 'Thabo'})
//  stat.save()
// Triggers console log on change stream
// {_id: '...', operationType: 'insert', ...}

const dataKeys = {
	TOTAL_PTS: 'total_pts',
	FAN_PTS: 'fan_pts',
	FAN_PTS_STRING: 'fan_pts_str',
	STATS: 'stats',
	GAME_STATUS: 'status',
	GAME_SCORE: 'score',
}

// var isTabActive;

// function toggleWindowActive(isActive, activeCallback) {
// 	const wasActive = isTabActive
// 	isTabActive = isActive;
// 	if (!wasActive && isActive && activeCallback) {
// 		activeCallback();
// 	}
// }

function getStats(stat, label) {
	return stat !== undefined && stat !== null && stat !== 0 && `<span>${stat} ${label}</span>`;
}

function canCollapse() {
	return window.innerWidth <= 850;
}

function getOffset(el) {
	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY
	};
}

function getLineStats(stats, position) {

	let posStatsHTML = '';
	switch (position) {
		case 'K':
			posStatsHTML = [
				getStats(stats.extra_points_made, 'XPs'),
				getStats(stats.field_goals_made20to29, 'FG 20+'),
				getStats(stats.field_goals_made30to39, 'FG 30+'),
				getStats(stats.field_goals_made40to49, 'FG 40+'),
				getStats(stats.field_goals_made50_plus, 'FG 50+'),
			]
			break;
		case 'DEF':
			const tdStats = (stats.punt_return_touchdowns || 0) + (stats.interception_return_touchdowns || 0) + (stats.kick_return_touchdowns || 0) + (stats.fumble_return_touchdowns || 0) + (stats.blocked_kick_return_touchdowns || 0) + (stats.field_goal_return_touchdowns || 0);
			posStatsHTML = [
				getStats(stats.points_allowed, 'PA'),
				getStats(stats.sacks, 'Sacks'),
				getStats(stats.interceptions, 'Ints'),
				getStats(stats.fumbles_recovered, 'Fum Recs'),
				getStats(stats.blocked_kicks, 'Blcks'),
				getStats(tdStats, 'TDs'),
			];
			break;

		default:
			posStatsHTML = [
				getStats(stats.passing_yards, 'Pass Yds'),
				getStats(stats.passing_touchdowns, 'Pass TDs'),
				getStats(stats.passing_interceptions, 'Ints'),
				getStats(stats.receptions, 'Rec'),
				getStats(stats.receiving_yards, 'Rec Yds'),
				getStats(stats.receiving_touchdowns, 'Rec Tds'),
				getStats(stats.rushing_yards, 'Rush Yds'),
				getStats(stats.rushing_touchdowns, 'Rush Tds'),
				getStats(stats.fumbles, 'Fum'),
			];
			break;
	}
	return posStatsHTML
		.filter(stat => stat !== false)
		.join(', ');
}


function updatePlayerData({ id, updatedFields }) {
	const playerCellsWithId = document.querySelectorAll(`[data-playerId="${id}"]`);
	let scoresUpdated;
	playerCellsWithId.forEach(node => {
		let didUpdate;

		Object.entries(updatedFields).forEach(([key, value]) => {
			const fieldCell = node.querySelector(`[data-key="${key}"]`);

			if (fieldCell) {
				const {position} = node.dataset;

				if (key === dataKeys.STATS) {
					fieldCell.innerHTML = getLineStats(value, position);
				} else if (key === dataKeys.FAN_PTS) {
					fieldCell.textContent = Number(value).toFixed(2);
					const fanPtsStringField = node.querySelector(`[data-key="${dataKeys.FAN_PTS_STRING}"]`);

					// when a player goes from having no pts data to some pts data, even if 0
					if (value !== undefined && fanPtsStringField.innerHTML === '-') {
						fanPtsStringField.textContent = '';
					}
				}
				if (!didUpdate) {
					didUpdate = true;
				}
				if (!scoresUpdated && key === dataKeys.FAN_PTS) {
					scoresUpdated = true;
				}
			}

		})
		if (didUpdate) {
			node.classList.add('live-update')
			node.addEventListener('animationend', () => node.classList.remove('live-update'));
		}
		if (scoresUpdated) {
			recalculateTeamPoints();
		}
	})
}

function updateGameData({ games }) {
	games.forEach(game => {
		const gameNodes = document.querySelectorAll(`[data-gameid="${game.identifier}"]`);

		if (gameNodes.length > 0) {
			gameNodes.forEach(node => {
				const {teamid} = node.dataset;
				const statusNode = node.querySelector(`[data-key="${dataKeys.GAME_STATUS}"]`);
				const scoreNode = node.querySelector(`[data-key="${dataKeys.GAME_SCORE}"]`);
				if (statusNode) {
					const newStatus = getGameStatusString(teamid, game);
					statusNode.textContent = newStatus;
					const itemNode = statusNode.closest('.item');

					if (itemNode) {
						if (!gameIsLive(game) && itemNode.classList.contains('is-live')) {
							itemNode.classList.remove('is-live');
						} else if (gameIsLive(game) && !itemNode.classList.contains('is-live')) {
							itemNode.classList.add('is-live');
						}
					}
				}
				if (scoreNode) {
					scoreNode.textContent = getGameScoreString(teamid, game) + ' ';
				}
			})
		}
	})
}

function recalculateTeamPoints() {
	const updatedMatchupBoxes = [];
	const matchupBoxes = [...container.querySelectorAll('.matchup-box')];
	matchupBoxes.forEach((matchupBox) => {
		const fanPtsCells = matchupBox.querySelectorAll(`:scope [data-key="${dataKeys.FAN_PTS}"]`);
		let totalPts = 0;
		if (fanPtsCells.length > 0) {
			Array.from(fanPtsCells).forEach(cell => {
				return totalPts += Number(cell.innerHTML || 0)
			});
			totalPts = totalPts.toFixed(2);
		}
		const totalPtsCell = matchupBox.querySelector(`:scope [data-key="${dataKeys.TOTAL_PTS}"]`);
		totalPtsCell.innerText = totalPts || 0;
		updatedMatchupBoxes.push({ matchupBox, totalPts });
	})
	const matchupBoxesContainer = container.querySelector('.matchup-boxes');
	updatedMatchupBoxes.sort((a, b) => b.totalPts - a.totalPts);

	// set old positions
	const oldPositions = [];
	updatedMatchupBoxes.forEach(({ matchupBox }, i) => {
		if (matchupBoxes.indexOf(matchupBox) !== i) {
			const { offsetHeight, offsetWidth } = matchupBox;
			oldPositions[i] = getOffset(matchupBox);
			const inner = matchupBox.querySelector('.matchup-box__inner');
			matchupBox.style.height = offsetHeight + 'px';
			matchupBox.style.width = offsetWidth + 'px';
			matchupBox.style.zIndex = updatedMatchupBoxes.length - i;
			inner.style.height = offsetHeight + 'px';
			inner.style.width = offsetWidth + 'px';
			inner.style.position = 'absolute';
		}
		matchupBoxesContainer.appendChild(matchupBox);
	});

	// reposition matchup boxes
	updatedMatchupBoxes.forEach(({ matchupBox }, i) => {
		if (matchupBoxes.indexOf(matchupBox) !== i) {
			const inner = matchupBox.querySelector('.matchup-box__inner');
			const { left: oldLeft, top: oldTop } = oldPositions[i];
			const { left, top } = getOffset(matchupBox);
			const originX = oldLeft - left;
			const originY = oldTop - top;
			inner.style.left = originX + 'px';
			inner.style.top = originY + 'px';
			matchupBox.classList.add('is-moving');
		}
	})

	setTimeout(() => {
		updatedMatchupBoxes.forEach(({ matchupBox }, i) => {
			if (matchupBoxes.indexOf(matchupBox) !== i) {
				const inner = matchupBox.querySelector('.matchup-box__inner');
				inner.style.left = 0 + 'px';
				inner.style.top = 0 + 'px';
				setTimeout(() => {
					matchupBox.classList.remove('is-moving');
					matchupBox.removeAttribute('style');
					inner.removeAttribute('style');
				}, 1000)
			}
		})
	}, 50)
}

const playersToWatch = [];
const defensesToWatch = [];
const gamesToWatch = [];
async function loadData(week) {

	let { teams, games } = await app.api.get(`/fantasy/league/${currentLeague._id}/matchup`, {
		week: week
	});

	const teamRosters = await Promise.all(Object.keys(teams).map(async (team) => {
		return app.api.get(`/fantasy/team/${team}`, { week })
	}));
	const pointsCalculator = (lookup) => {
		const players = lookup.team.positions.filter(({ week: curWeek }) => curWeek === week);
		const stats = lookup.positions_stats;
		return players.reduce((a, b) => {
			const foundStats = stats && b && stats[b.player ? b.player._id : b.team];
			const newVal = foundStats ? foundStats.fan_pts : 0;

			return a + newVal;
		}, 0).toFixed(2)
	}

	teamRosters.sort((a, b) => {
		return pointsCalculator(b) - pointsCalculator(a);
	})

	container.querySelector('.matchup-boxes').innerHTML = teamRosters.map((teamData, i) => {
		const { team, positions_stats } = teamData;

		const rows = POSITIONS.groups.map((group, i) => {
			const players = group.map(pos => {
				let player = team.positions.find(({ position, week: curWeek }) => position === pos && curWeek === week);
				const gameId = player && player.game_id;

				if (player && player.player) {
					player = player.player;
				};
				const isDef = pos === 'DEF';
				const playerId = player ? isDef ? player.team : player._id : null;
				const stats = playerId && positions_stats[playerId];

				const gameData = games.find(({ identifier }) => identifier === gameId)
				const isLive = gameData && gameIsLive(gameData);
				if (playerId) {
					if (!isDef && playersToWatch.indexOf(playerId) === -1) {
						playersToWatch.push(playerId);
					} else if (isDef && defensesToWatch.indexOf(playerId) !== -1) {
						defensesToWatch.push(playerId);
					}
				}

				if (	gameId && gamesToWatch.indexOf(gameId) === -1) {
					gamesToWatch.push(gameId)
				}

				const gameStatus = player && getGameStatusString(player.team, gameData)
				const gameScore = player && getGameScoreString(player.team, gameData);
				const vs = player && getVsString(player.team, gameData);

				return `
						<div class="item flex${!player ? ' inactive' : ' active'}${isLive ? ' is-live' : ''}" data-position="${pos}" data-playerId="${playerId}">
							<div class="column pos">
								<div class="position">
									<span class="name">${pos}</span>
								</div>
							</div>
							${player ? `
								<div class="column player">
									<span class="player-info">
										<div class="player-info-meta">
											<span class="name">${pos === 'DEF' ? TEAMS[player.team].full_name : player.first_name + ' ' + player.last_name}
												<span class="team-pos bold small">${player.team} - ${pos}</span>
											</span>
										</div>
										${gameData && `
										<div class="player-info-game-status" data-gameid="${gameData.identifier}" data-teamid="${player.team}">
												<span class="time small">
													<span data-key="${dataKeys.GAME_STATUS}">${gameStatus}</span>
													<span data-key="${dataKeys.GAME_SCORE}">${gameScore ? gameScore + ' ' : ''}</span><span
														class="bold">${vs}</span></span>
											</div>
										`}
											${stats ? `
												<div class="player-info-stats">
													<span class="stats small" data-key="${dataKeys.STATS}">${stats ? getLineStats(stats.stats, pos) : ''}</span>
												</div>` : ``
											}
									</span>
									<span class="player-status">
										${player.status == 'questionable' ? '<span class="name status bold">Q</span>' : player.status == 'doubleful' ? '<span class="name status bold">D</span>' : ''}
									</span>
								</div>
								<div class="column pts ">
									<span class="name"><span data-key="${dataKeys.FAN_PTS}">${stats ? Number(stats.fan_pts || 0).toFixed(2) : ""}</span><span data-key="${dataKeys.FAN_PTS_STRING}">${stats ? "" : "-"}</span></span>
								</div>
							` : ''}
					</div>`

			}).join('')

			return `
					<div class="header subheader flex">
						<div class="column pos flex">
							<span>Pos.</span>
						</div>
						<div class="column flex">
							<span>Player</span>
						</div>
						<div class="column pts flex">
							<span>Fan Pts</span>
						</div>
					</div>

					<div class="items group-${i}">
						${players}
					</div>
				`
		}).join('')
		const isUser = team.user === app.auth.getUserId()

		return `
		<div class="matchup-box">
			<div class="matchup-box__inner">
				<div class="matchup-box__top">
					<div class="flex team-info">
						<h2>
							<span class="team-name">${team.name}</span>
							<span class="points-total"> <span data-key="${dataKeys.TOTAL_PTS}">${pointsCalculator(teamData)}</span><sub>pts</sub></span>
						</h2>
						<div class="dropdown-arrow"></div>
					</div>

					<div class="header rank flex ${isUser ? 'user' : ''}">
						<span class="column">
							<span>Rank: ${(parseInt(i) + 1).nth()} Place</span>
						</span>
					</div>
				</div>
				<div class="matchup-box__content" ${canCollapse() && !isUser ? `data-collapsed="true"` : ""}>
					${rows}
				</div>
			</div>
		</div>
		`;
	}).join('');
	if (ws && playersToWatch.length > 0) {
		ws.send(JSON.stringify({
			type: 'playersToWatch',
			data: playersToWatch
		}));
	}

	if (ws && defensesToWatch.length > 0) {
		ws.send(JSON.stringify({
			type: 'defensesToWatch',
			data: defensesToWatch
		}));
	}

	if (ws && gamesToWatch.length > 0) {
		ws.send(JSON.stringify({
			type: 'gamesToWatch',
			data: gamesToWatch
		}));
	}
}

const toggleMatchupbox = (matchupBox) => {
	const content = matchupBox.querySelector('.matchup-box__content');
	if (content && canCollapse()) {
		const isCollapsed = content.dataset.collapsed;
		if (isCollapsed) {
			content.removeAttribute('data-collapsed');
		} else {
			content.setAttribute('data-collapsed', "true")
		}
	}
}

function addListeners() {
	// window.addEventListener('focus', toggleWindowActive.bind(this, true));
	// window.addEventListener('blur', toggleWindowActive.bind(this, false));
	container.querySelectorAll('.matchup-box').forEach(matchupBox => {
		const trigger = matchupBox.querySelector('.matchup-box__top');
		if (trigger) {
			trigger.addEventListener('click', () => toggleMatchupbox(matchupBox));
		}
	});
}

function removeListeners() {
	// window.removeEventListener('focus', toggleWindowActive.bind(this, true));
	// window.removeEventListener('blur', toggleWindowActive.bind(this, false));
	container.querySelectorAll('.matchup-box').forEach(matchupBox => {
		const trigger = matchupBox.querySelector('.matchup-box__top');
		if (trigger) {
			trigger.removeEventListener('click', () => toggleMatchupbox(matchupBox));
		}
	});
}

let currentLeague;
let season;
export default {
	show: async function (leagueData, seasonData, week) {

		if (seasonData) season = seasonData;

		if (leagueData) currentLeague = leagueData;

		container.querySelector('h1').innerText = `Leaderboard`;
		week = getWeek(week, season);

		weekSelector.populate(seasonData, week);
		weekSelector.select(week);

		window.history.pushState(null, null, `${window.location.pathname}?week=${week}`);

		// Connect to websocket
		if (ws) {
			await ws.close();
		}

		ws = await new Promise((resolve, reject) => {
			const timeoutId = setTimeout(() => {
				console.error('Couldn\'t establish websocket, live updates canceled');
				resolve();
			}, 2000) // wait 2 sec

			connectToServer().then(ws => {
				clearTimeout(timeoutId)
				resolve(ws)
			})
		})
		if (ws) {
			ws.onmessage = async (webSocketMessage) => {
				const {data} = webSocketMessage;
				if (!data) return;
				const parsedData = JSON.parse(data)
				switch (parsedData.type) {
					case 'playerUpdate':
						updatePlayerData(parsedData)
						break;
					case 'gameUpdate':
						updateGameData(parsedData)
						break;
					case 'liveGameAnnouncement':
						console.log('new game live, fteching again')
						await loadData(week)
						break;
					default:
						break;
				}
			}

		}

		await loadData(week);

		// toggleWindowActive(true);
		addListeners();
	},
	hide: function () {
		if (ws) {
			ws.close();
		}
		removeListeners();
	}
}
